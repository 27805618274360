<template>
  <div class="uploadandview">
    <label
      for="uploadandview"
      :class="['viewer', invalido ? 'invalid-upload' : '']"
    >
      <span
        v-if="!(value_ && value_.length)"
        class="text-muted"
      >
        Inclua uma imagem de até 1 MB (megabyte)
      </span>
      <img :src="value_ && value_.length ? value_[0].url : ''">
    </label>
    <div>
      <file-upload
        ref="uploadandview"
        v-model="value_"
        :input-id="identificadorUnico"
        :disabled="loading"
        class="btn btn-primary btn-sm load"
        :multiple="false"
        :size="maxSize"
        @input-file="inputFile"
        @input-filter="inputFilter"
      >
        <i class="fas fa-plus" />
        Carregar {{ titulo }}
      </file-upload>
      <b-btn
        :disabled="!value_ || value_.length == 0"
        variant="none"
        size="sm"
        class="btn-outline-danger btn-none ml-1"
        @click="remove()"
      >
        <feather-icon icon="TrashIcon" />
      </b-btn>
    </div>
  </div>
</template>

<style lang="scss">
.uploadandview {
  display: flex;
  flex-direction: column;

  .viewer {
    float: left;
    min-width: 40%;
    width: fit-content;
    margin: 0 30px 20px 0;
    border: 2px solid rgba(24, 28, 33, 0.3);
    border-style: dashed;
    border-radius: 4px;
    padding: 1rem;

    &:nth-child(2n) {
      clear: left;
    }

    &:nth-child(2n + 1) {
      margin-right: 0;
    }

    img {
      object-fit: contain;
      height: 120px;
    }
  }

  .btn.load {
    border-radius: 2px;
  }

  .invalid-upload {
    border-color: rgba(244, 0, 30, 0.5)
  }
}
</style>

<script>
/* eslint-disable */
import FileUpload from 'vue-upload-component'
import mixins from '@pilar/mixins/upload'

export default {
  name: 'Attachview',
  components: {
    FileUpload,
  },
  mixins: [mixins],
  props: {
    value: {
    },
    upload: {
      type: Function,
    },
    titulo: {
      type: String,
      default: 'logo',
    },
    invalido: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      identificadorUnico: Date.now().toString(36) + Math.random().toString(36).substring(2),
      value_: [],
      loading: false,
      maxSize: 1024 * 1024,
      extensions: 'gif;jpg;jpeg;png;webp',
    }
  },
  watch: {
    value(value, oldValue) {
      if (value !== oldValue && value && !oldValue) {
        this.$emit('autoupload', false)
        const binary = atob(value)
        const array = []
        for (let i = 0; i < binary.length; i += 1) {
          array.push(binary.charCodeAt(i))
        }
        const file = new Blob([new Uint8Array(array)])
        this.$refs.uploadandview.add(file)
      }
    },
  },
  methods: {
    headers() {
      return {
        Authorization: `Bearer ${this.$store.getters['auth/accessToken']}`,
        departs: this.$store.getters['auth/departments'],
      }
    },
    remove() {
      this.$refs.uploadandview.remove(this.value_[0])
      this.$emit('autoupload', true)
    },
    inputFilter(newFile, oldFile, prevent) {
      const file = newFile
      if (file && !oldFile) {
        if (this.isDangerFile(file.name)) {
          this.$error('Arquivos não permitidos')
          return prevent()
        }

        if (!this.allowExtension(file.name, this.extensions)) {
          this.$erro(`Apenas arquivos ${this.extensions.replaceAll(';', ', ')} são permitidos`)
          return prevent()
        }

        if (this.invalidFileSize(this.maxSize, file.size)) {
          this.$erro('Imagem maior do que o tamanho permitido!')
          return prevent()
        }
      }

      if (file && (!oldFile || file.file !== oldFile.file)) {
        file.url = ''
        const URL = window.URL || window.webkitURL
        if (URL && URL.createObjectURL) {
          file.url = URL.createObjectURL(file.file)
        }
      }
      return null
    },
    inputFile(newFile, oldFile) {
      if (newFile && oldFile) {
        if (newFile.active && !oldFile.active && this.maxSize) {
          if (this.invalidFileSize(this.maxSize, newFile.size)) {
            this.$refs.uploadandview.update(newFile, { error: 'Tamanho inválido' })
          }
        }

        if (newFile.error && !oldFile.error) {
          this.$error(`Não foi possível salvar o arquivo ${newFile.name}. ${newFile.error}`)
          this.$refs.uploadandview.remove(newFile)
        }
      }
    },
    getFormDataBody(body) {
      const formData = new window.FormData()
      const file = this.value_[0]
      if (file) {
        formData.append('file', file.file, file.filename || file.name)
      }
      formData.append('body', JSON.stringify(body))

      return formData
    },
  },
}
</script>
